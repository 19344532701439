.masterclass-box {
  width: 300px;
  height: 450px;
  border-radius: 30px;
  position: relative;
  margin: 20px;
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.masterclass-box:hover {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.masterclass-box:hover .speaker-image {
  filter: none;
}

.speaker-photo {
  height: 75%;
}

.gradient {
  width: 100%;
  height: 70%;
  border-radius: 0px 0px 30px 30px;
  position: absolute;
  bottom: 0;
  z-index: 5;
}

.speaker-image {
  position: absolute;
  top: 0;
  height: 70%;
  width: 100%;
  object-fit: cover;
  border-radius: 30px 30px 0 0;
  filter: saturate(0);
  transition: all 0.2s ease-in-out;
}


.text-info {
  color: white;
  position: absolute;
  bottom: 0;
  height: 35%;
  z-index: 6;
  padding: 8%;
}
.find-more {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.masterclass-completed {
  height: 10%;
  width: 100%;
  background-color: #f9c200;
  position: absolute;
  top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  z-index: 7;
}

.apps-texts {
  position: absolute;
  z-index: 5;
}

.find-more-btn {
  background-color: white;
  border-radius: 13px;
  font-weight: 700;
  height: 26px;
  padding: 0 12px;
  font-size: 12px;
  border: none;
  cursor: pointer;
  color: #040027;
}
.text-date-amount {
  display: flex;
  flex-direction: row;
}

.students-amount {
  margin-left: 12%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
