@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', serif;
}

a {
  margin: 0;
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
}

.header-logo {
  height: 50px;
  object-fit: contain;
  margin-top: 5px;
}

.header-container {
  height: 9vh;
  width: 100%;
}

.tickets-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.title-master {
  font-weight: 800;
  font-size: 3.4rem;
  color: #040027;
  line-height: 1.2;
  margin-bottom: 5vh;
}

header {
  /* height: 9vh; */
  /* width: -webkit-fill-available; */
  width: 100%;
  box-sizing: border-box;
  padding: 5px 8%;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  z-index: 10;
}

.header {
  width: 0;
}

#apps {
  height: 100vh;
}

.navigation-items {
  position: absolute;
  right: 8%;
}

.navigation-item-secondary {
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin: 5px 20px;
  text-decoration: none;
  color: #040027;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  position: relative;
  width: fit-content;
}

.navigation-item-secondary:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -2px;
  left: 0;
  background-color: #f9c200;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navigation-item-secondary:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navigation-item-primary {
  font-size: 12px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 120px;
  margin: 0 15px;
  background-color: #fbe900;
  border-radius: 10px;
  color: #040027;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.cta-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trapeze {
  top: 9vh;
  height: 91vh;
  width: 65vw;
  z-index: 3;
  background: url(../../assets/trapeze.svg);
  background-size: cover;
}

.more-btn {
  width: 220px;
  height: 45px;
  text-decoration: none;
  background-color: white;
  border-radius: 30px;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #040027;
  margin-top: 5vh;
  transition: all 0.2s ease-in-out;
}

.navigation-item-primary:hover {
  transform: scale(1.1);
}

.more-btn:hover {
  transform: scale(1.1);
}

.photo-slider {
  position: absolute;
  right: 0;
  height: 91vh;
  width: 65vw;
  z-index: 1;
  background-color: #282c34;
}

.each-slide {
  height: 91vh;
  width: 100%;
  object-fit: cover;
}

.apps-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.apps-texts {
  width: 40vw;
  margin-left: 8%;
}

#masterclasses {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.big-title {
  font-weight: 800;
  font-size: 40px;
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 3vh;
  color: #040027;
}

.masterclass-items {
  display: flex;
  max-width: 1200px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.questions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.discount-container {
  width: 100%;
  margin-top: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#faq,
#discount {
  min-height: 100vh;
  margin: 0 8%;
}

.bm-burger-button {
  display: none;
  position: absolute;
  top: 3vh;
  width: 36px;
  height: 30px;
  right: 8%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #040027;
}

.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  opacity: 0.8;
  animation: pulse 1.5s infinite ease-in-out;
}

.discount-text {
  color: grey;
  text-align: center;
  width: 45vw;
  font-size: 14px;
}

.highlight {
  background-color: #f1f1f1;
  color: #040027;
  height: 10px;
  font-weight: bold;
  padding: 0 7px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.highlight:hover {
  color: #fbe900;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.logo-loader {
  height: 200px;
  width: 200px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fbe900;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

#react-burger-menu-btn {
  z-index: 5 !important;
}

.bm-menu {
  background: white;
  padding: 1em 5% 0;
  font-size: 1.5em;
  height: auto !important;
  border-radius: 0 0 30px 30px;
  z-index: -1000 !important;
}

.bm-menu-wrap {
  top: 9vh;
  width: 100% !important;
  z-index: -1000 !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: transparent;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 25px;
  color: #040027;
}

.bm-overlay {
  position: absolute !important;
  top: 38vh !important;
  background: transparent !important;
  height: 62vh !important;
  left: 0;
  z-index: -900 !important;
}

footer {
  height: 40vh;
  background: #f1f1f1;
  color: #040027;
}

.footer-body {
  display: flex;
  margin-top: 10vh;
  justify-content: space-between;
  height: 85%;
}

.footer-sponsors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-left: 7%;
}

.footer-sponsor-logos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.apps-logo {
  height: 75px;
  margin: 0 50px;
}

.ucu-logo {
  height: 82px;
}

.footer-info-part {
  display: flex;
  margin-top: 8vh;
  height: 50%;
  margin-right: 8%;
  align-items: center;
}

.footer-section-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  color: #040027;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  position: relative;
  width: fit-content;
}

.footer-section-button:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -2px;
  left: 0;
  background-color: #f9c200;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footer-section-button:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.footer-section-buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.footer-find-us {
  margin-left: 100px;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
}

.footer-find-us-title {
  margin-bottom: 15px;
}

.find-us-icons {
  display: flex;
  justify-content: space-between;
  width: 150px;
}

.footer-find-us-circle {
  height: 40px;
  width: 40px;
  background-color: #c4c4c4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-find-us-circle a {
  width: 20px;
  height: 20px;
}

.footer-copyright {
  height: 25%;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.copyright-logo-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-text {
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
}

.ukrainian-flag {
  display: flex;
  flex-direction: column;
  height: 35%;
  width: 5%;
  max-width: 100px;
  padding-right: 10px;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-in-out;
}

.ukrainian-flag:hover {
  transform: scale(1.2);
}

.yellow {
  height: 100%;
  width: 100%;
  background-color: #f8d548;
}

.blue {
  height: 100%;
  width: 100%;
  background-color: #183dab;
}

@media only screen and (max-width: 740px) {
  .navigation-items {
    display: none;
  }

  .bm-burger-button {
    display: block;
  }

  .header {
    padding: 0;
    top: 0;
    position: fixed;
    height: 10vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: -10;
  }

  footer {
    height: auto;
  }

  .footer-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-with-support {
    margin-top: 40px;
  }

  .footer-sponsor-logos {
    display: flex;
    flex-direction: column;
  }

  .footer-sponsors {
    padding: 0;
  }

  .apps-logo {
    margin-bottom: 10px;
  }

  .footer-info-part {
    display: flex;
    flex-direction: column;
  }

  .footer-section-buttons {
    align-items: center;
    height: 130px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .footer-info-part {
    margin: 0;
  }

  .footer-find-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 35px;
  }

  .copyright-logo-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .trapeze {
    top: 9vh;
    height: 91vh;
    width: 100vw;
    z-index: 3;
    background: none;
    background-color: rgba(253, 233, 0, 0.8);
  }

  .title-master {
    font-size: 2rem;
  }

  .discount-text {
    width: 70vw;
  }

  .apps-texts {
    width: 60vw;
    margin-left: 0;
    text-align: center;
  }

  .apps-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo-slider {
    height: 91vh;
    width: 100vw;
    z-index: 2;
  }

  .more-btn {
    margin: 5vh auto 0 auto;
  }

  #masterclasses,
  #faq,
  #discount {
    height: auto;
  }

  .faq-box {
    margin: 20px 30px;
  }
}
