.ticket-box a {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket-box {
  color: #040027;
  background-color: white;
  height: 280px;
  width: 250px;
  min-width: 210px;
  box-shadow: 0px 6px 29px rgba(0, 0, 0, 0.12),
    0px 1.34018px 6.47753px rgba(0, 0, 0, 0.0715329),
    0px 0.399006px 1.92853px rgba(0, 0, 0, 0.0484671);
  border-radius: 20px;
  /* padding: 20px 35px; */
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  padding: 20px 15px;
  transition: all 0.3s ease-in-out;
}

.ticket-box:hover {
transform: scale(1.1);
}

.ticket-box button {
  color: #040027;
}

.ticket-title {
  font-weight: 800;
  font-size: 20px;
  width: 80%;
}

.ticket-price {
  font-weight: 800;
  font-size: 28px;
  text-align: left;
}
.price-left {
  width: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ticket-desc {
  width: 80%;
  font-size: 14px;
}
.ticket-link {
  width: 80%;
  height: 35px;
  font-weight: bold;
  background-color: #fbe900;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.ticket-availbale {
  font-size: 14px;
  color: grey;
}
