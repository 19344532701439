.signup-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: #040027;
}

.logo-signup {
  height: 80px;
}

.signup-modal-text {
  overflow-x: scroll;
  max-height: 60vh;
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.signup-choose-mc {
  display: flex;
  flex-direction: column;
}

.signup-choosers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.signup-input-fields {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.input-signup {
  background-color: #e6e6e6;
  height: 40px;
  width: 250px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  padding: 0 15px;
}

.signup-button-proceed {
  height: 40px;
  width: 170px;
  background-color: #f9c200;
  color: #040027;
  border: none;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 4vh;
  transition: all 0.2s ease-in-out;
}

.signup-button-proceed:hover {
transform: scale(1.1);
}

.signup-subtitle {
  font-weight: 700;
  font-size: 16px;
}

[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: solid 1px #cccccc;
  border-radius: 5px;
  margin-right: 8px;
  position: relative;
}

[type="checkbox"]:checked::before {
  content: "";
  width: 14px;
  height: 14px;
  background-color: #f9c200;
  border-radius: 2px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.checkbox-signup {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.modal-signup {
  padding: 35px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signup-main-content {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .signup-container {
    flex-direction: column;
  }

  .signup-screen {
    height: fit-content;
    margin: 5vh 10px;
  }
  .logo-signup {
    height: 60px;
  }
  .signup-main-content {
    height: fit-content;
    gap: 20px;
    width: 90vw;
  }

  h2 {
    text-align: center;
  }

  .signup-button-proceed {
    margin-top: 0;
  }
}
