.modal {
  width: 65%;
  height: 75%;
  background-color: #ffffff;
  color: #040027;
}
.modal-body {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-part {
  flex: 3;
  background-repeat: no-repeat;
  background-size: cover;
}

.info-part {
  /* background-color: aqua; */
  flex: 5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.info-part-body {
  /* background-color:beige; */
  height: 85%;
  width: 85%;
  /* background-color: #f9c200; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-title {
  font-size: 2rem;
  /* margin-bottom: 10px; */
  line-height: 2.2rem;
}

.modal-description {
  font-size: 0.9rem;
  color: #797272;
  height: 60%;
  overflow-y: scroll;
}

.modal-subtitle {
  font-size: 1rem;
  margin-bottom: 10px;
}

.modal-requirements-list {
  list-style: inside;
  /* margin-bottom: 20px; */
}

.modal-requirements-list li {
  font-size: 0.9rem;
  margin-left: 20px;
}

.modal-cta-button {
  font-size: 14px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  height: 40px;
  width: 150px;
  background-color: #f9c200;
  border-radius: 10px;
  border: none;
  color: #040027;
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .image-part {
    display: none;
  }

  /* .modal {
    height: 60%;
  } */
}

@media screen and (max-width: 720px) {
  .info-part-body {
    /* background-color:beige; */
    height: 88%;
    gap: 20px;
    /* background-color: #f9c200; */
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .modal-description {
    font-size: 0.9rem;
  }

  .modal-requirements-list li {
    font-size: 0.9rem;
  }

  .modal {
    width: 70%;
    height: 75%;
  }
}
@media screen and (max-width: 520px) {
  .modal {
    width: 80%;
    height: 75%;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
